import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const NavBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="static" elevation={0} sx={{ 
      backgroundColor: 'transparent', 
      color: theme.palette.primary.main,
      borderBottom: '1px solid',
      borderColor: theme.palette.divider,
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography 
          variant={isMobile ? "h6" : "h5"} 
          component={Link} 
          to="/" 
          sx={{ 
            textDecoration: 'none', 
            color: 'inherit',
            fontFamily: '"Playfair Display", serif',
            fontWeight: 700,
            '&:hover': {
              color: theme.palette.primary.dark,
            },
          }}
        >
          Digitala Snapsvisor
        </Typography>
        <Box>
          <Button
            component={Link}
            to="/"
            color="inherit"
            sx={{ 
              mr: 2,
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
              },
            }}
          >
            Hem
          </Button>
          <Button
            component={Link}
            to="/new"
            color="inherit"
            startIcon={<AddIcon />}
            sx={{
              border: '1px solid',
              borderColor: 'inherit',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            }}
          >
            {isMobile ? 'Ny' : 'Ny Snapsvisa'}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;