import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBfowbmePEycWUzE0PzenTV6R3A2dZgnWc",
  authDomain: "snapsdags.firebaseapp.com",
  databaseURL: "https://snapsdags-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "snapsdags",
  storageBucket: "snapsdags.appspot.com",
  messagingSenderId: "179314488455",
  appId: "1:179314488455:web:517cf0e99658361c883422",
  measurementId: "G-XQHRDJV216",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
