import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

interface Song {
  id: number;
  name: string;
  melody: string;
}

// Create a custom theme
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1a3a5a', // Dark blue
    },
    background: {
      default: '#f5f5f5', // Light grey background
    },
  },
  typography: {
    fontFamily: '"Playfair Display", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e0e0e0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
});

const SongList: React.FC = () => {
  const [songs, setSongs] = useState<Song[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const songsRef = ref(db, "songs");
    onValue(
      songsRef,
      (snapshot) => {
        setLoading(true);
        const data = snapshot.val();

        if (data) {
          const loadedSongs = Object.entries(data).map(([key, value]) => ({
            id: parseInt(key),
            name: (value as any).name,
            melody: (value as any).melody,
          }));
          setSongs(loadedSongs);
        } else {
          setSongs([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching songs:", error);
        setError("Failed to fetch songs. Please try again later.");
        setLoading(false);
      }
    );
  }, []);

  const filteredSongs = songs.filter(
    (song) =>
      song.name.toLowerCase().includes(search.toLowerCase()) ||
      song.melody.toLowerCase().includes(search.toLowerCase())
  );

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, color: 'primary.main' }}>
          Snapsvisor
        </Typography>
        <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
          <TextField
            placeholder="Sök snapsvisa"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: 'primary.main', mr: 1 }} />
              ),
            }}
            sx={{ 
              flexGrow: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: 0,
              }
            }}
          />
          <Button
            component={Link}
            to="/new"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Ny Snapsvisa
          </Button>
        </Box>
        <Grid container spacing={3}>
          {filteredSongs.map((song) => (
            <Grid item xs={12} sm={6} md={4} key={song.id}>
              <Link to={`/song/${song.id}`} style={{ textDecoration: "none" }}>
                <Card sx={{ 
                  height: '100%', 
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  },
                }}>
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ mb: 1, color: 'primary.main' }}>
                      {song.id}. {song.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {song.melody}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
        {filteredSongs.length === 0 && (
          <Typography variant="body1" sx={{ mt: 3, textAlign: "center" }}>
            Inga snapsvisor hittades. Prova att söka på något annat eller lägg till en ny snapsvisa!
          </Typography>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default SongList;