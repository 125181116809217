import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";
import {
  Typography,
  Box,
  Container,
  Button,
  useMediaQuery,
  useTheme,
  Fade,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { Helmet } from "react-helmet";
import { logEvent } from "../analytics";

interface Song {
  id: number;
  name: string;
  melody: string;
  text: string;
}

const SongView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [song, setSong] = useState<Song | null>(null);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const songRef = ref(db, `songs/${id}`);
    onValue(
      songRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setSong({ id: parseInt(id!), ...data });
          logEvent("Song", "View", data.name);
        } else {
          console.log("No such song!");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching song:", error);
        setLoading(false);
      }
    );
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: "center" }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (!song) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: "center" }}>
        <Typography color="error">Song not found.</Typography>
      </Container>
    );
  }

  const pageUrl = `https://snapsdags.com/song/${song.id}`;

  return (
    <>
      <Helmet>
        <title>{`${song.name} - Digitala Snapsvisor`}</title>
        <meta
          name="description"
          content={`Läs och sjung ${song.name} till melodin ${song.melody}. En populär svensk snapsvisa.`}
        />
        <link rel="canonical" href={pageUrl} />
        {/* Open Graph and Twitter Card tags here */}
        {/* Structured data here */}
      </Helmet>
      <Fade in={true} timeout={800}>
        <Container maxWidth="sm" sx={{ mt: 6, mb: 6 }}>
          <Button
            component={Link}
            to="/"
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 4, color: 'text.secondary', '&:hover': { backgroundColor: 'transparent', color: 'primary.main' } }}
          >
            Tillbaka till listan
          </Button>
          <Typography variant="overline" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
            Snapsvisa #{song.id}
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontFamily: '"Playfair Display", serif',
              fontWeight: 700,
              color: 'primary.main',
              mb: 3,
            }}
          >
            {song.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <MusicNoteIcon sx={{ mr: 1, color: 'secondary.main' }} />
            <Typography variant="subtitle1" color="text.secondary">
              {song.melody}
            </Typography>
          </Box>
          <Divider sx={{ mb: 4 }} />
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-wrap",
              fontFamily: '"Roboto Slab", serif',
              lineHeight: 1.8,
              fontSize: isMobile ? "1rem" : "1.1rem",
              mb: 4,
            }}
          >
            {song.text}
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="body2" color="text.secondary" align="center">
            Skål och sjung med glädje!
          </Typography>
        </Container>
      </Fade>
    </>
  );
};

export default SongView;