import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ref, push, set, get, runTransaction } from "firebase/database";
import { db } from "../firebase";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Fade,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { logEvent } from "../analytics";

const NewSong: React.FC = () => {
  const [song, setSong] = useState({ name: "", melody: "", text: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSong({ ...song, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submitted"); // Debug log
    setIsSubmitting(true);
    setError(null);

    try {
      console.log("Starting transaction"); // Debug log
      const songCountRef = ref(db, 'songCount');
      const songCountTransaction = await runTransaction(songCountRef, (currentCount) => {
        console.log("Current count:", currentCount); // Debug log
        return (currentCount || 0) + 1;
      });

      console.log("Transaction result:", songCountTransaction); // Debug log

      if (songCountTransaction.committed) {
        const newId = songCountTransaction.snapshot.val();
        console.log("New ID:", newId); // Debug log
        
        const newSongRef = ref(db, `songs/${newId}`);
        await set(newSongRef, { ...song, id: newId });
        console.log("New song added to database"); // Debug log

        setSuccess(true);
        logEvent('Song', 'Submit', song.name);
        setTimeout(() => navigate('/'), 2000);
      } else {
        throw new Error("Failed to get new song ID");
      }
    } catch (error) {
      console.error("Error adding new song:", error);
      setError("Failed to add new song. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <Fade in={true} timeout={800}>
      <Container maxWidth="sm" sx={{ mt: 6, mb: 6 }}>
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIcon />}
          sx={{
            mb: 4,
            color: "text.secondary",
            "&:hover": {
              backgroundColor: "transparent",
              color: "primary.main",
            },
          }}
        >
          Tillbaka till listan
        </Button>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontFamily: '"Playfair Display", serif',
            fontWeight: 700,
            color: "primary.main",
            mb: 4,
          }}
        >
          Ny Snapsvisa
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { mb: 3 },
            "& .MuiButton-root": { mt: 2 },
          }}
        >
          <TextField
            fullWidth
            required
            name="name"
            label="Visans namn"
            value={song.name}
            onChange={handleChange}
            variant="outlined"
            sx={{ "& .MuiOutlinedInput-root": { borderRadius: 0 } }}
          />
          <TextField
            fullWidth
            required
            name="melody"
            label="Melodi"
            value={song.melody}
            onChange={handleChange}
            variant="outlined"
            sx={{ "& .MuiOutlinedInput-root": { borderRadius: 0 } }}
          />
          <TextField
            fullWidth
            required
            name="text"
            label="Visans text"
            multiline
            rows={8}
            value={song.text}
            onChange={handleChange}
            variant="outlined"
            sx={{ "& .MuiOutlinedInput-root": { borderRadius: 0 } }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            startIcon={<SaveIcon />}
            sx={{
              py: 1.5,
              borderRadius: 0,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isSubmitting ? "Sparar..." : "Spara Snapsvisa"}
          </Button>
        </Box>
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Snapsvisan har sparats!
          </Alert>
        </Snackbar>
      </Container>
    </Fade>
  );
};

export default NewSong;
