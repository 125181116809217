import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import NavBar from './components/NavBar';
import SongList from './components/SongList';
import NewSong from './components/NewSong';
import SongView from './components/SongView';
import { initGA, logPageView } from './analytics';

const TRACKING_ID = "G-4P77TNS35K";

const theme = createTheme();

const App: React.FC = () => {
  useEffect(() => {
    initGA(TRACKING_ID);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <>
      <CssBaseline />
      <NavBar />
      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={<SongList />} />
          <Route path="/new" element={<NewSong />} />
          <Route path="/song/:id" element={<SongView />} />
        </Routes>
      </Container>
    </>
  );
};

export default App;